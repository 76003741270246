<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box mt20px">
    <ayl-main title="SIM卡信息" :isCollapse="false">
      <div slot="main" class="p24px">
        <div class="contents">
            <span>所属企业:</span>
            <span> {{ data.companyName ||'- -' }}</span>
        </div>
        <div class="contents">
            <span>SIM卡号:</span>
            <span> {{ data.simNumber ||'- -' }}</span>
        </div>
        <div class="contents">
            <span>运营商:</span>
            <span> {{ $enums_hw.ServiceProvider.getServiceProvider(data.serviceProvider) ||'- -' }}</span>
        </div>
        <div class="contents">
            <span>套餐:</span>
            <span> {{ data.pricePackage ||'- -' }}</span>
        </div>
        <div class="contents">
            <span>提醒天数:</span>
            <span> {{ data.alarmDay ||'- -' }}</span>
        </div>
        <div class="contents">
            <span>到期时间:</span>
            <span> {{ data.expireDate|str2ymd }}</span>
        </div>
        <div class="contents">
            <span>所属标段:</span>
            <span> {{ data.bidName ||'- -' }}</span>
        </div>
        <div class="contents">
            <span>备注:</span>
            <span> {{ data.remark ||'- -' }}</span>
        </div>
    </div>
    </ayl-main>
    <div class="tac" style="margin-top: 40px">
      <el-button style="margin-left: 30px" @click="onClose">返回</el-button>
    </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        nav: [
          {name: "SIM卡信息"},
        ],
		    data:{},
      }
    },
    methods:{
      onClose() {
        BUS.$emit(BUSEVENT.CLOSE_NAV, this.$route);
      }
    },
    async mounted () {
      this.data = await this.$api_hw.simManagement_getSimInfoBySimNumber({
        simNumber: this.$route.query.simNumber
      })
    }
  }
</script>
<style lang='sass' scoped>
</style>
